var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "_profile" },
    [
      _c(
        "vs-tabs",
        { attrs: { position: _vm.isSmallScreen(500) ? "top" : "left" } },
        [
          _c(
            "vs-tab",
            {
              class: { "pt-0": !_vm.isSmallScreen(500) },
              attrs: { label: _vm.$t("general") },
            },
            [_c("profile-details")],
            1
          ),
          _c(
            "vs-tab",
            {
              class: { "pt-0": !_vm.isSmallScreen(500) },
              attrs: { label: _vm.$t("password") },
            },
            [_c("vx-card", [_c("profile-password")], 1)],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }