var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "_profile-details" }, [
    _c(
      "div",
      { staticClass: "w-12/12" },
      [
        _c(
          "div",
          {
            staticClass:
              "grid justify-items-center grid-cols-1 bg-grey bg-opacity-25 rounded-t-lg p-5",
          },
          [
            _c("div", [
              _c(
                "div",
                {
                  staticClass: "overlay-container z-20",
                  on: {
                    mouseenter: function ($event) {
                      _vm.uploadOverlay = true
                    },
                    mouseleave: function ($event) {
                      _vm.uploadOverlay = false
                    },
                    click: function ($event) {
                      _vm.avatarEditor.visible = true
                    },
                  },
                },
                [
                  _vm.uploadOverlay
                    ? _c("feather-icon", {
                        staticClass:
                          "overlayed flex-shrink z-10 my-0 inset-0 cursor-pointer bg-contain rounded-full bg-black bg-opacity-75",
                        attrs: {
                          icon: "CameraIcon",
                          svgClasses:
                            "mx-auto my-0 w-10 h-10 text-primary opacity-75",
                        },
                      })
                    : _vm._e(),
                  _c("vs-avatar", {
                    key: _vm.key,
                    staticClass:
                      "border-white border-5 m-0 border-solid ring-inset",
                    attrs: { src: _vm.myProfilePicture, size: "100px" },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "grid grid-rows-2" }, [
              _c("span", { staticClass: "text-lg text-center font-semibold" }, [
                _vm._v(_vm._s(`${_vm.user.name} ${_vm.user.surname}`)),
              ]),
              _c("span", { staticClass: "text-sm sm:text-md text-center" }, [
                _vm._v(_vm._s(_vm.user.email)),
              ]),
            ]),
          ]
        ),
        _c(
          "vx-card",
          { staticClass: "relative text-base leading-loose mb-5" },
          [
            _c(
              "h4",
              { staticClass: "mx-auto text-center lg:text-left mb-10 lg:mb-5" },
              [_vm._v(" " + _vm._s(_vm.$t("dados-usuario")) + " ")]
            ),
            _c(
              "vs-row",
              { staticClass: "w-full gap-2" },
              [
                _c("vs-input", {
                  staticClass: "flex-grow",
                  attrs: { label: _vm.$t("name") },
                  model: {
                    value: _vm.user.name,
                    callback: function ($$v) {
                      _vm.$set(_vm.user, "name", $$v)
                    },
                    expression: "user.name",
                  },
                }),
                _c("vs-input", {
                  staticClass: "flex-grow",
                  attrs: { label: _vm.$t("sobrenome") },
                  model: {
                    value: _vm.user.surname,
                    callback: function ($$v) {
                      _vm.$set(_vm.user, "surname", $$v)
                    },
                    expression: "user.surname",
                  },
                }),
              ],
              1
            ),
            _c("vs-row", { staticClass: "mb-2 gap-2" }, [
              _c(
                "div",
                { staticClass: "flex-grow" },
                [
                  _c(
                    "vs-select",
                    {
                      staticClass: "w-full hover:text-primary",
                      attrs: { label: _vm.$t("genero") },
                      model: {
                        value: _vm.user.gender,
                        callback: function ($$v) {
                          _vm.$set(_vm.user, "gender", $$v)
                        },
                        expression: "user.gender",
                      },
                    },
                    [
                      _c("vs-select-item", {
                        attrs: { value: "female", text: _vm.$t("female") },
                      }),
                      _c("vs-select-item", {
                        attrs: { value: "male", text: _vm.$t("male") },
                      }),
                      _c("vs-select-item", {
                        attrs: {
                          value: "undisclosed",
                          text: _vm.$t("undisclosed"),
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.errors.has("gender"),
                          expression: "errors.has('gender')",
                        },
                      ],
                      staticClass: "text-danger text-sm",
                    },
                    [_vm._v(" " + _vm._s(_vm.errors.first("gender")) + " ")]
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "flex-grow" },
                [
                  _c(
                    "label",
                    {
                      staticClass:
                        "mr-2 align-middle vs-select--label inline-block",
                    },
                    [_vm._v(_vm._s(_vm.$t("data-de-nascimento")))]
                  ),
                  _c("datetime", {
                    attrs: {
                      "input-class":
                        "vs-inputx vs-input--input normal text-base font-semibold hover:text-primary cursor-pointer",
                      type: "date",
                      format: "dd/MM/yyyy",
                      "value-zone": "America/Sao_Paulo",
                      "use24-hour": "",
                    },
                    model: {
                      value: _vm.user.birthdate,
                      callback: function ($$v) {
                        _vm.$set(_vm.user, "birthdate", $$v)
                      },
                      expression: "user.birthdate",
                    },
                  }),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.errors.has("birthdate"),
                          expression: "errors.has('birthdate')",
                        },
                      ],
                      staticClass: "text-danger text-sm",
                    },
                    [_vm._v(" " + _vm._s(_vm.errors.first("birthdate")) + " ")]
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "flex-grow" }, [
                _c(
                  "form",
                  [
                    _c("vs-input", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "max:20|numeric",
                          expression: "'max:20|numeric'",
                        },
                      ],
                      staticClass: "w-full",
                      attrs: {
                        autocomplete: "off",
                        name: "user_phone",
                        label: "Telefone/Celular",
                        "data-vv-as": "Telefone/Celular",
                        "data-vv-validate-on": "input",
                      },
                      model: {
                        value: _vm.user.phone,
                        callback: function ($$v) {
                          _vm.$set(_vm.user, "phone", $$v)
                        },
                        expression: "user.phone",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.has("phone"),
                        expression: "errors.has('phone')",
                      },
                    ],
                    staticClass: "text-danger text-sm",
                  },
                  [_vm._v(" " + _vm._s(_vm.errors.first("phone")) + " ")]
                ),
              ]),
            ]),
            _c(
              "vs-row",
              [
                _c("label", { staticClass: "w-full" }, [
                  _vm._v(_vm._s(_vm.$t("cadastrado-desde"))),
                ]),
                _c("datetime", {
                  attrs: {
                    "input-class":
                      "vs-inputx vs-input--input normal border-none text-base font-semibold",
                    type: "date",
                    format: "dd/MM/yyyy",
                    "value-zone": "America/Sao_Paulo",
                    "use24-hour": "",
                    disabled: "",
                  },
                  model: {
                    value: _vm.since,
                    callback: function ($$v) {
                      _vm.since = $$v
                    },
                    expression: "since",
                  },
                }),
              ],
              1
            ),
            _c(
              "vs-row",
              [
                _c(
                  "vs-col",
                  [
                    _c(
                      "vs-button",
                      {
                        staticClass: "float-right",
                        on: { click: _vm.updateProfile },
                      },
                      [_vm._v(_vm._s(_vm.$t("action.save")))]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "vs-popup",
          {
            directives: [
              {
                name: "tooltip",
                rawName: "v-tooltip.left",
                value: {
                  content:
                    "Clique no editor ou arraste um arquivo para alterar a imagem.",
                  delay: { show: 500, hide: 100 },
                  classes: "tooltipZFix",
                },
                expression:
                  "{\n        content: 'Clique no editor ou arraste um arquivo para alterar a imagem.',\n        delay: { show: 500, hide: 100 },\n        classes: 'tooltipZFix'\n      }",
                modifiers: { left: true },
              },
              {
                name: "esc",
                rawName: "v-esc",
                value: _vm.closeAvatarPhoto,
                expression: "closeAvatarPhoto",
              },
            ],
            attrs: {
              title: _vm.$t("foto-perfil"),
              active: _vm.avatarEditor.visible,
            },
            on: {
              "update:active": function ($event) {
                return _vm.$set(_vm.avatarEditor, "visible", $event)
              },
            },
          },
          [
            _c(
              "vs-row",
              { staticClass: "grid place-items-center" },
              [
                _c("avatar-editor", {
                  ref: "avatareditor",
                  staticClass:
                    "border-1 border-primary border-double vs-col place-self-center",
                  staticStyle: { height: "256px" },
                  attrs: {
                    width: 200,
                    height: 200,
                    scale: _vm.avatarEditor.scale,
                    rotation: _vm.avatarEditor.rotation,
                    borderRadius: 200,
                    image: _vm.myProfilePicture,
                  },
                  on: { "vue-avatar-editor:image-ready": _vm.onImageReady },
                }),
                _c(
                  "div",
                  {
                    staticClass:
                      "EditorButtons mt-2 flex justify-between w-1/3 space-x-3",
                  },
                  [
                    _c("feather-icon", {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip.auto",
                          value: {
                            content: "Ampliar",
                            delay: { show: 500, hide: 100 },
                            classes: "tooltipZFix",
                          },
                          expression:
                            "{\n              content: 'Ampliar',\n              delay: { show: 500, hide: 100 },\n              classes: 'tooltipZFix'\n            }",
                          modifiers: { auto: true },
                        },
                      ],
                      attrs: {
                        icon: "ZoomInIcon",
                        svgClasses: _vm.aditionalStyleWhen(
                          _vm.zoomingIn,
                          "w-8 h-8 lg:w-5 lg:h-5 hover:primary",
                          "primary"
                        ),
                      },
                      on: {
                        mousedown: function ($event) {
                          return _vm.doZoom(1)
                        },
                        mouseup: function ($event) {
                          return _vm.doZoom(0)
                        },
                        click: function ($event) {
                          return _vm.tinyZoom(1)
                        },
                      },
                    }),
                    _c("feather-icon", {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip.auto",
                          value: {
                            content: "Reduzir",
                            delay: { show: 500, hide: 100 },
                            classes: "tooltipZFix",
                          },
                          expression:
                            "{\n              content: 'Reduzir',\n              delay: { show: 500, hide: 100 },\n              classes: 'tooltipZFix'\n            }",
                          modifiers: { auto: true },
                        },
                      ],
                      attrs: {
                        icon: "ZoomOutIcon",
                        svgClasses: _vm.aditionalStyleWhen(
                          _vm.zoomingOut,
                          "w-8 h-8 lg:w-5 lg:h-5 hover:primary",
                          "primary"
                        ),
                      },
                      on: {
                        mousedown: function ($event) {
                          return _vm.doZoom(-1)
                        },
                        mouseup: function ($event) {
                          return _vm.doZoom(0)
                        },
                        click: function ($event) {
                          return _vm.tinyZoom(-1)
                        },
                      },
                    }),
                    _c("feather-icon", {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip.auto",
                          value: {
                            content: "Girar sentido horário",
                            delay: { show: 500, hide: 100 },
                            classes: "tooltipZFix",
                          },
                          expression:
                            "{\n              content: 'Girar sentido horário',\n              delay: { show: 500, hide: 100 },\n              classes: 'tooltipZFix'\n            }",
                          modifiers: { auto: true },
                        },
                      ],
                      attrs: {
                        icon: "RotateCwIcon",
                        svgClasses: _vm.aditionalStyleWhen(
                          _vm.rotatingCW,
                          "w-8 h-8 lg:w-5 lg:h-5 hover:primary",
                          "primary"
                        ),
                      },
                      on: {
                        click: function ($event) {
                          return _vm.doRotate(1, 45)
                        },
                      },
                    }),
                    _c("feather-icon", {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip.auto",
                          value: {
                            content: "Girar sentido anti-horário",
                            delay: { show: 500, hide: 100 },
                            classes: "tooltipZFix",
                          },
                          expression:
                            "{\n              content: 'Girar sentido anti-horário',\n              delay: { show: 500, hide: 100 },\n              classes: 'tooltipZFix'\n            }",
                          modifiers: { auto: true },
                        },
                      ],
                      attrs: {
                        icon: "RotateCcwIcon",
                        svgClasses: _vm.aditionalStyleWhen(
                          _vm.rotatingCCW,
                          "w-8 h-8 lg:w-5 lg:h-5 hover:primary",
                          "primary"
                        ),
                      },
                      on: {
                        click: function ($event) {
                          return _vm.doRotate(-1, 45)
                        },
                      },
                    }),
                    _c("feather-icon", {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip.auto",
                          value: {
                            content: "Limpar",
                            delay: { show: 500, hide: 100 },
                            classes: "tooltipZFix",
                          },
                          expression:
                            "{\n              content: 'Limpar',\n              delay: { show: 500, hide: 100 },\n              classes: 'tooltipZFix'\n            }",
                          modifiers: { auto: true },
                        },
                      ],
                      attrs: {
                        icon: "XIcon",
                        svgClasses: _vm.aditionalStyleWhen(
                          _vm.rotatingCCW,
                          "w-8 h-8 lg:w-5 lg:h-5 hover:primary",
                          "primary"
                        ),
                      },
                      on: { click: _vm.clearEdits },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "vx-row",
              [
                _c(
                  "vx-col",
                  [
                    _c(
                      "vs-button",
                      {
                        staticClass: "float-right mr-2",
                        attrs: { disabled: !_vm.validDetails },
                        on: { click: _vm.updateProfilePicture },
                      },
                      [_vm._v(_vm._s(_vm.$t("action.save")))]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }